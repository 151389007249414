.container :global .modal-content {
    max-height: calc(100vh - 1rem);
    max-height: calc(var(--vh, 1vh) * 100 - 1rem);
}

@media (max-width: 767.98px) {
    .container :global .modal-content {
        height: calc(100vh - 1rem);
        height: calc(var(--vh, 1vh) * 100 - 1rem);
    }
}

.container :global .modal-dialog {
    margin-top: 0.5rem;
    margin-bottom: 0.45rem;
}

.container :global .modal-body {
    overflow: auto;
}

.content {

}

.sectionTitle {
    font-weight: 500;
    font-size: 1.4rem;
    margin: 25px 0 10px;
}

.footer {
    display: flex;
    justify-content: space-between;
}