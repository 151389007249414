.childrenList {
    margin-top: 25px;
}

.childrenList li {
    margin: 2px 0;
}

.sitsCount {
    margin-left: 4px;
    font-size: 0.9rem;
}