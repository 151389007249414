.container {
    justify-content: center;
    margin-top: 30px;
}

.container :global .page-link {
    border: none !important;
    color: var(--bs-body-color) !important;
    border-radius: 50% !important;
    padding: 0px;
    display: inline-block;
    width: 2rem;
    text-align: center;
    line-height: 2rem;
    margin: 0px 2px !important;
    font-size: 0.9rem;
}

.container:global > li.active > .page-link {
    color: white !important;
    background-color: var(--bs-body-color) !important;
}