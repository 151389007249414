.container {
    padding-top: 1.5rem;
}


@media (min-width: 992px) {
    .container {
        height: calc(100vh - 140px);
    }
}

.header {
    font-size: 0.85rem;
    line-height: 60px;
}

.stats {
    text-align: center;
    font-size: 0.8rem;
    margin-bottom: 20px;
}

.spinner {
    text-align: center;
    padding: 100px 0;
}
