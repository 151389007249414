.platformsList {
    margin-top: 20px;
}

.platformsList li {
    margin: 3px 0;
    font-size: 1.05rem;
}

.platformLogoWrapper {
    height: 20px;
    display: inline-block;
    width: 30px;
    text-align: center;
    margin-right: 5px;
}

.platformLogo {
    height: auto;
    max-height: 100%;
}

.sitsCount {
    margin-left: 4px;
    font-size: 0.9rem;
}