.container {
    padding: 10px var(--fluid-padding) !important;
    border-top: 1px solid var(--bs-gray-300);
    background-color: var(--bs-gray-200);
    font-size: 0.8rem;
    display: flex;
}

.nav {

}

.nav a {
    padding: 0 10px;
    color: inherit !important;
}

@media (max-width: 575px) {
    .nav {
        margin: 0 -10px;
    }
}

.affiliate {
    font-size: 0.7rem;
    align-self: center;
}