.button {
    display: inline-block;
    font-weight: 400;
    line-height: 1.7;
    color: var(--bs-gray-700) !important;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid var(--bs-gray-300);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-radius: 5px;
}

.button:hover {
    border-color: var(--bs-gray-500);
}

.buttonActive {
    border-color: var(--bs-gray-700) !important;
    background-color: var(--bs-gray-100);
}