.container {
    padding: 15px var(--fluid-padding);
    border-bottom: 1px solid var(--bs-gray-300) !important;
}

.item {
    display: inline-block;
    height: 38px;
    vertical-align: initial !important;
    position: relative;
}