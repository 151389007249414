.container {
    line-height: 40px;
    border: 1px solid var(--bs-gray-300);
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    user-select: none;
    cursor: pointer;
}

.container:hover {
    background-color: var(--bs-gray-100);
}

.animalIcon {
    font-size: 18px;
    color: #63686d;
    margin-right: 8px;
    width: 25px;
}

.icon {
    display: inline-block;
    padding: 0 2px;
    margin: 0 2px;
    cursor: pointer;
    transition: opacity 0.1s ease-in-out;
}

.icon:hover {
    opacity: 1 !important;
}

.icon svg {
    vertical-align: middle !important;
}