body {
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: #f27d7a;
  --fluid-padding: 25px;
  --footer-height: 50px;
}

.primary-padding {
  padding-left: var(--fluid-padding);
  padding-right: var(--fluid-padding);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*@media (min-width: 1600px) {*/
/*  .row-cols-xxl-3>* {*/
/*    flex: 0 0 auto;*/
/*    width: 25%;*/
/*  }*/
/*}*/

.btn-outline-secondary {
  color: var(--bs-gray-700);
  border-color: var(--bs-gray-300);
}

.btn-outline-secondary:hover {
  color: inherit;
  background-color: inherit;
  border-color: var(--bs-gray-500);
}

body {
  line-height: 1.75;
}

p {margin-bottom: 1rem;}

h1, h2, h3, h4, h5 {
  margin: 3rem 0 1.38rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 2.488rem;
}

h2 {font-size: 2.074rem;}

h3 {font-size: 1.728rem;}

h4 {font-size: 1.44rem;}

h5 {font-size: 1.2rem;}