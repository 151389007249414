.responsibilities {
    /*display: grid;*/
    /*grid-template-columns: repeat(2, 1fr);*/
    /*gap: 10px 15px;*/
    margin-bottom: 15px;
}

.bulkActions {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    margin-bottom: 20px;
}

.bulkActionsButton {
    margin: 0px 5px;
    cursor: pointer;
}

@media (max-width: 575px) {
    .bulkActionsButton:first-child {
        margin-left: 0;
    }
    .bulkActionsButton:last-child {
        margin-right: 0;
    }
}

.bulkActionsButton svg {
    margin-left: 5px;
}

.bulkActionsTitle {
    position: relative;
    top: 3px;
}