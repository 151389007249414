.infoTableWrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin: 20px 0;
}

.infoTable {
    /*width: 100%;*/
}

.infoTable, .infoTable tr, .infoTable td {
    border-collapse: collapse;
}

.infoTable td:first-child {
    padding-right: 5px;
    font-weight: 500;
    width: 300px;
}

.infoTable td:nth-child(2) {
    font-weight: 400;
}

@media (min-width: 768px) {
    .infoTable td:first-child {
        min-width: 250px;
        padding-right: 20px;
    }
}

@media (max-width: 767px) {
    .infoTable tr {
        border-bottom: 1px solid var(--bs-gray-300);
    }
}