.tableContainer {
    margin: 30px 0;
    overflow-x: auto;
}

.table {
    width: 100%;
    border-radius: 5px;
}

.table td {
    line-height: 1.3;
}

.table thead {

}

.table tr {
    height: 75px;
}

.table td, .table th {
    padding: 0 15px;
}

.cellPrice {
    text-align: center;
}

.cellPrice b {
    font-size: 1.05rem;
}

.cellPrice div {
    color: var(--bs-gray-700);
}

.table th {
    height: 50px;
    font-weight: 500;
    line-height: 1;
    /*text-align: center;*/
}

.table th:nth-child(2) {
    width: 150px;
}

.table th:nth-child(4) {
    width: 100px;
}

.table th:nth-child(5) {
    width: 150px;
}

.table .smaller {
    font-size: 0.75rem;
}

.titleLogo {
    display: inline-block;
    width: 30px;
    text-align: center;
    margin-right: 10px;
    position: relative;
    top: -2px;
}

.titleLogo img {
    height: 26px;
}

.title {
    font-size: 1.25rem;
}

.readReview {
    font-size: 0.8rem;
    margin-top: 5px;
}

.table, .table th, .table td {
    border: 2px solid var(--bs-gray-300);
}

@media (min-resolution: 120dpi) {
    .table, .table th, .table td {
        border-width: calc(0.75px * 2);
    }
}

@media (min-resolution: 144dpi) {
    .table, .table th, .table td {
        border-width: calc(0.666px * 2);
    }
}

@media (min-resolution: 192dpi) {
    .table, .table th, .table td {
        border-width: calc(0.5px * 2);
    }
}