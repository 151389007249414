.container {
    text-decoration: none;
    color: inherit;
    display: block;
}

.container:hover {
    color: inherit;
}

.image {
    width: 100%;
    padding-top: 66%;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    background-color: var(--bs-gray-200);
    position: relative;
}

.title {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: -2px;
    margin-top: 5px;
}

.dates {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    color: #6c6c6c;
}

.location {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    color: #6c6c6c;
}

.animalIcon {
    font-size: 18px;
    color: #63686d;
    margin-right: 6px;
}

.animalIconContainer {
    vertical-align: sub;
}

.footer {
    display: flex;
    justify-content: space-between;
    height: 25px;
}

.logo {
    height: auto;
    max-height: 100%;
    vertical-align: top;
}

.foundOn {
    vertical-align: bottom;
    font-size: 0.75rem;
    margin-right: 8px;
}

.badges {
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.badge {
    font-weight: 300 !important;
    vertical-align: top;
    text-transform: uppercase;
    font-size: 0.6rem !important;
    margin-bottom: 3px;
}

.badges :global .bg-light {
    color: var(--bs-gray-700);
}