.container {
    padding: 0 var(--fluid-padding) !important;
}

.containerWithBorder {
    border-bottom: 1px solid var(--bs-gray-300);
}

.logo {
    color: var(--primary-color) !important;
    line-height: 60px;
    font-size: 28px !important;
    /*font-weight: 500;*/
    letter-spacing: -1px;
}

.logoSecondPart {
    color: var(--bs-body-color);
    margin-left: 5px;
    /* text-transform: uppercase; */
    /* font-weight: 500; */
    letter-spacing: -1px;
}

.navLink {
    color: var(--bs-gray-600) !important;
    font-size: 1.1rem;
}

.navLink:hover {
    color: var(--bs-gray-800) !important;
}

@media (min-width: 768px) {
    :global .navbar-expand-md .navbar-nav .nav-link {
        padding-left: 0.7rem !important;
        padding-right: 0.7rem !important;
    }
}
