.container {
    position: absolute;
    z-index: 1000;
    border: 1px solid var(--bs-gray-300);
    border-radius: 10px;
    background-color: white;
    top: 50px;
    padding: 20px;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}