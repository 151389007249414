.location {
    padding: 8px 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
}

.location:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.locationType {
    color: rgba(0, 0, 0, 0.55);
    font-size: 15px;
}

.input {
    font-weight: 400 !important;
    line-height: 1.7 !important;
    color: rgb(34, 34, 34) !important;
    font-size: 0.9rem !important;
    border: 1px solid rgb(221, 221, 221) !important;
}

.inputWrapper {
    position: relative;
}

.inputClear {
    position: absolute;
    font-size: 20px;
    top: calc(50% - 10px);
    right: 13px;
    color: var(--bs-gray-600);
}

.content {

}

.content .input {
    margin-bottom: 10px;
}

.results {
    padding: 7px 0;
}